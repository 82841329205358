import locale_nl from 'dayjs/locale/nl'
import locale_en from 'dayjs/locale/en'

export const dayJs = (date: string) => {
  const locale = baseI18nGetActiveCode() === 'nl' ? locale_nl : locale_en
  return baseDayJs(date).locale(locale)
}

export const startOfDay = (date: string) => {
  return new Date(date).setHours(0, 0, 0, 0)
}

export const endOfDay = (date: string) => {
  return new Date(date).setHours(23, 59, 59, 999)
}

export const formatDateToDefault = (date: any) => {
  return dayJs(date).format('YYYY-MM-DD')
}
